/* eslint-disable @next/next/no-img-element */
import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import css from "./style.module.scss";
import { useKeycloak } from "@react-keycloak/ssr";
import Image from "next/image";
import chevronDownIcon from "../../public/images/np_chevron-down.svg";
import logoutIcon from "../../public/images/np_log-out.svg";
import { useRouter } from "next/router";
import { Domain } from "../../types/common";

const SERVICES = [
  {
    title: "Chat",
    link: "https://www.chat.kita.bayern",
    icon: "/images/Header_navbar_Chat.svg",
  },
  {
    title: "Meeting",
    link: "https://www.meeting.kita.bayern",
    icon: "/images/Header_navbar_Meeting.svg",
  },
  {
    title: "Kurse",
    link: "https://www.kurse.kita.bayern",
    icon: "/images/Header_navbar_Kurse.svg",
  },

  /* SERVICES_HIDDEN_TEMP */
  // {
  //   title: "Kurzlinks",
  //   link: "https://www.l.kita.bayern",
  //   icon: "/images//external-link.svg",
  //   style: { width: 25 },
  // },
  // {
  //   title: "Terminplaner",
  //   link: "https://www.termine.kita.bayern",
  //   icon: "/images/kalender.svg",
  //   style: { width: 25 },
  // },
  // {
  //   title: "Notizen",
  //   link: "https://www.notizen.kita.bayern",
  //   icon: "/images/notizzettel.svg",
  //   style: { width: 25 },
  // },
];

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(
  ref: React.MutableRefObject<HTMLDivElement | null>,
  callback: VoidFunction,
  exceptClasses: string[] = []
) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      const isExcempt = Array.from(
        (event.target as HTMLElement).classList
      ).some((klass) => exceptClasses.includes(klass));
      if (
        ref.current &&
        !ref.current.contains(event.target as any) &&
        !isExcempt
      ) {
        callback();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export const useLogin = () => {
  const { keycloak, initialized } = useKeycloak();

  const tokenParsed: {
    given_name: string;
    family_name: string;
    email: string;
  } = (keycloak as any)?.tokenParsed;

  const login = useCallback(() => {
    (keycloak as unknown as { login: VoidFunction })?.login();
  }, [keycloak]);

  const logout = useCallback(
    (evt: React.MouseEvent) => {
      evt.stopPropagation();
      (keycloak as unknown as { logout: VoidFunction })?.logout();
    },
    [keycloak]
  );

  return {
    tokenParsed,
    login,
    logout,
    initialized: !process.browser ? false : initialized,
  };
};

// currently idm is running on v20 in which redirect_uri was changed to post_logout_redirect_uri
// unfortunately react-keycloak/ssr is deprecated and still uses the redirect_uri
// TODO upgrade to react-auth
const logoutWorkaroundLink = {
  dev: "https://idm-dev.kita.bayern/auth/realms/KITA/protocol/openid-connect/logout?client_id=landingpage-dev&post_logout_redirect_uri=https%3A%2F%2Fwww.dev.kita.bayern%2F",
  prod: "https://idm.kita.bayern/auth/realms/KITA/protocol/openid-connect/logout?client_id=landingpage&post_logout_redirect_uri=https%3A%2F%2Fwww.kita.bayern%2F",
};

export const NavBar = ({
  isDev,
  domain,
}: {
  isDev: boolean;
  domain: Domain;
}) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const wrapperMobileRef = useRef<HTMLDivElement | null>(null);
  const profileRef = useRef<HTMLDivElement | null>(null);

  const [toolsOpened, setToolsOpened] = useState(false);
  const [profileOpened, setProfileOpened] = useState(false);

  function toggleToolsOpened() {
    setToolsOpened((s) => !s);
  }

  useOutsideAlerter(
    profileRef,
    () => {
      setProfileOpened(false);
    },
    [css["user-name"]]
  );

  useOutsideAlerter(wrapperRef, () => {
    if (document.body.clientWidth >= 778) {
      setToolsOpened(false);
    }
  });

  useOutsideAlerter(
    wrapperMobileRef,
    () => {
      if (document.body.clientWidth < 778) {
        setToolsOpened(false);
      }
    },
    [css["tools-icon"], css["mobile-expand-icon"]]
  );

  const { login, logout, tokenParsed, initialized } = useLogin();

  let MainLogo;

  switch (domain) {
    case Domain.KITAHUB:
      MainLogo = "/images/logo-kita-hub.svg";
      break;
    case Domain.PIXELWERKSTATT:
      MainLogo = "/images/kita-hub-logo-werkstatt.svg";
      break;
    case Domain.MEDIENECKE:
      MainLogo = "/images/kita-hub-logo-medien.svg";
      break;
  }

  const router = useRouter();
  if (router.query.mode === "APP") {
    return null;
  }

  return (
    <>
      <div className={css["mobile-nav-bar"]}>
        <div className={css["topnav"]}>
          <div className="d-flex justify-content-between p-3">
            <a
              href={
                isDev
                  ? "https://www.dev.kita.bayern"
                  : "https://www.kita.bayern"
              }
              aria-label="Link zur Startseite des KITA HUB"
            >
              <Image
                className={css["kita-logo"]}
                src={MainLogo}
                width={120}
                height={50}
                alt=""
              />
            </a>
            <div
              className={classNames(css["icon"], css["mobile-expand-icon"])}
              onClick={(evt) => {
                setToolsOpened((s) => !s);
              }}
              role="button"
              aria-label="Dienste ausklappen"
            >
              <Image
                className={css["tools-icon"]}
                src={require("../../public/images/Navbar_Menu.svg")}
                alt=""
              />
            </div>
          </div>
          <div
            ref={wrapperMobileRef}
            className={classNames(css["links"], { [css.opened]: toolsOpened })}
          >
            {!tokenParsed && (
              <a href="#login" aria-label="Anmeldung" onClick={login}>
                Anmeldung
              </a>
            )}
            {tokenParsed && (
              // <a href="#login" aria-label="Anmeldung" onClick={logout}>
              <a
                href={
                  isDev ? logoutWorkaroundLink.dev : logoutWorkaroundLink.prod
                }
                aria-label="Abmelden"
              >
                Abmelden
              </a>
            )}

            {/* <a href="#login" aria-label="Anmeldung">
              Registrierung
            </a> */}

            {SERVICES.map((service) => (
              <a
                key={service.title}
                href={service.link}
                aria-label={service.title}
                target="_blank"
                rel="noreferrer"
              >
                {service.title}
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className={classNames(css["desktop-nav-bar"])}>
        <div className={css["logo"]}>
          <img
            className={css["logo-background"]}
            src="/images/Header_Logo_Background.svg"
            alt=""
          />
          <div className={css["logo-foreground"]}>
            <a
              href={
                isDev
                  ? "https://www.dev.kita.bayern"
                  : "https://www.kita.bayern"
              }
            >
              <Image
                className={css["kita-logo"]}
                src={MainLogo}
                width={120}
                height={50}
                alt=""
              />
            </a>
          </div>
        </div>
        {/* <div className={css["right-header-image"]}>
          <img
            style={{ width: "100%" }}
            src="/images/Header_navbar_right.svg"
            alt=""
          />
        </div> */}

        <div
          className={classNames(css["green-circle"], {
            "d-none": !initialized,
          })}
        ></div>
        <div
          className={classNames(css["yellow-circle"], {
            "d-none": !initialized,
          })}
        ></div>

        <div
          ref={wrapperRef}
          className={classNames(
            "d-flex gap-3 align-items-center",
            css["action-bar"],
            { "d-none": !initialized }
          )}
        >
          {!tokenParsed && (
            <>
              <button
                onClick={login}
                aria-label="Anmeldung"
                className={classNames(
                  "text-uppercase",
                  css["button"],
                  css["login-button"]
                )}
              >
                Anmeldung
              </button>
              {/* <button
                aria-label="Anmeldung"
                className={classNames(
                  "text-uppercase",
                  css["button"],
                  css["register-button"]
                )}>
                Registrierung
              </button> */}
            </>
          )}
          {tokenParsed && (
            <div style={{ position: "relative" }}>
              <div
                aria-label="Anmeldung"
                className={classNames(
                  "text-uppercase",
                  css["button"],
                  css["user-name"]
                )}
                onClick={() => setProfileOpened((s) => !s)}
              >
                <img
                  className={css["user-logo"]}
                  src="/images/np_person.svg"
                  alt=""
                />
                {tokenParsed.given_name} {tokenParsed.family_name}
              </div>
              <div
                ref={profileRef}
                className={classNames(css["tools-dropdown"], {
                  [css.opened]: profileOpened,
                })}
              >
                {/* <div onClick={logout}> */}
                <a
                  href={
                    isDev ? logoutWorkaroundLink.dev : logoutWorkaroundLink.prod
                  }
                  aria-label="Abmelden"
                  className={css["logout-button"]}
                >
                  <Image
                    className={css["logout-icon"]}
                    src={logoutIcon}
                    width={30}
                    alt=""
                  />
                  <span style={{ marginLeft: 10 }}>Abmeldung</span>
                </a>
              </div>
            </div>
          )}
          <div style={{ position: "relative" }}>
            <button
              className={classNames(
                css["tools-container"],
                css["button"],
                css["utils-button"]
              )}
              onClick={() => toggleToolsOpened()}
              aria-label="Dienste ausklappen"
            >
              {/* <img
              className={css["tools-icon"]}
              src="/images/np_tool.svg"
              alt=""
              /> */}
              <Image
                className={classNames(css["chevron-icon"], {
                  [css.opened]: toolsOpened,
                })}
                src={chevronDownIcon}
                width={20}
                height={20}
                alt=""
              />
              Dienste
            </button>

            <div
              className={classNames(css["tools-dropdown"], {
                [css.opened]: toolsOpened,
              })}
            >
              {SERVICES.map((service) => (
                <a
                  key={service.title}
                  href={service.link}
                  className={css["service-item"]}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div>
                    <img src={service.icon} alt="" />
                    {service.title}
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
