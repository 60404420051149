import React from "react";
import Image from "next/image";
import classNames from "classnames";
import css from "./style.module.css";
import ifpIcon from "../../public/images/logo-ifp-footer.svg";
import { Domain } from "../../types/common";

export const Footer = ({ domain }: { domain: Domain }) => {
  const footerLinks = [
    {
      label: "Datenschutzerklärung",
      link: "https://www.legal.kita.bayern/datenschutz",
    },
    {
      label: "Impressum",
      link: "https://www.legal.kita.bayern/impressum",
    },
    {
      label: "Nutzungsbedingungen",
      link: "https://www.legal.kita.bayern/nutzungsbedingungen",
    },
    ...(domain === Domain.MEDIENECKE
      ? [
          {
            label: "Redaktionsstatut",
            link: "/redaktionsstatut",
          },
        ]
      : []),
  ];

  return (
    <div className={css["footer-container"]}>
      <div className={classNames(css["left-side"])}>
        <div className={classNames(css["grid"])}>
          {footerLinks.map((l) => (
            <a
              key={l.link}
              href={l.link}
              target="_blank"
              rel="noreferrer"
              className={classNames(css["grid-item"])}
            >
              {l.label}
            </a>
          ))}
        </div>
      </div>

      <div className={css["middle-section"]}></div>

      <div className={classNames(css["icon-wrapper"], css["right-side"])}>
        <div className="d-flex justify-content-end my-2">
          <a href="https://www.ifp.bayern.de" target="_blank" rel="noreferrer">
            <Image
              className={classNames(css["logo-footer"])}
              src={ifpIcon}
              width={160}
              height={70}
              alt="Logo des Staatsinstituts für Frühpädagogik. Das Logo zeigt die Abkürzung / Akronym IFP und um das Schriftzeichen sind 5 kleine mit Striche stilisierte Personen abgebildet. Es sieht aus als würden sie Hand in Hand gehen und die unterschiedliche Größe der Strichpersonen sollen Eltern und Kinder bzw. Erwachsene und Kinder symbolisieren."
            />
          </a>
        </div>

        <div className={classNames(css["mobile-links"])}>
          <div className={classNames(css["grid"])}>
            {footerLinks.map((l) => (
              <a
                key={l.link}
                href={l.link}
                target="_blank"
                rel="noreferrer"
                className={classNames(css["grid-item"])}
              >
                {l.label}
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
